import { lazy, Suspense, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isUserAuthorized } from "./store/selectors";
import { loadInitialData } from "./actions/authActions";
import { Loading } from "./layout/utils/AppLoading";
import { getCookie } from "./utils";
import {
  APP_COOKIE_NAME,
  AUTH,
  PASS_COOKIE_NAME,
  USER_COOKIE_NAME,
} from "./constants";
import withClearCache from "./ClearCache";

const MainContainer = lazy(() => import("./containers/MainContainer"));
const AuthContainer = lazy(() => import("./containers/AuthContainer"));

const App = () => {
  const dispatch = useDispatch();

  const ClearCacheComponent = withClearCache(() => {
    return <></>;
  });

  const { auth } = useSelector(isUserAuthorized) || {};

  const fetchInitialData = useCallback(() => {
    dispatch(loadInitialData());
  }, [dispatch]);

  useEffect(() => {
    Promise.all([
      getCookie(USER_COOKIE_NAME),
      getCookie(PASS_COOKIE_NAME),
      getCookie(APP_COOKIE_NAME),
    ]).then((credentials) => {
      if (credentials[2] && credentials[1] && credentials[0]) {
        dispatch({
          type: AUTH,
          payload: {
            auth: true,
            almacen: credentials[1],
            user: credentials[0],
          },
        });
      }
    });

    if (auth) fetchInitialData();
  }, [auth, fetchInitialData, dispatch]);

  return (
    <Suspense fallback={<Loading centered />}>
      <ClearCacheComponent />
      {auth ? <MainContainer /> : <AuthContainer />}
    </Suspense>
  );
};

export default App;
