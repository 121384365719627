/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASSWORD_COOKIE_NAME = "PASSWORD_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
/* <---- BASIC APP CONSTANTS ----> */

/* KEYAPP */
export const SHIPMENTS_TYPE = "shipments";
export const TICKETS_TYPE = "tickets";
export const RELOCATES_TYPE = "relocates";
export const RETURNS_TYPE = "returns";
export const DELIBERYNOTE_TYPE = "deliberyNote";
export const PRODUCTS_TYPE = "products";
/* <---- KEYAPP ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
export const CREATE_ROUTE = "CREATE_ROUTE";
export const DELETE_ROUTE = "DELETE_ROUTE";
/* <---- ROUTES ----> */

/* SHIPMENTS */
export const SET_SHIPMENTS = "SET_SHIPMENTS";
export const SET_SHIPMENTS_LINES = "SET_SHIPMENTS_LINES";
/* <---- SHIPMENTS ----> */

/* TICKETS */
export const SET_TICKETS = "SET_TICKETS";
export const SET_TICKETS_LINES = "SET_TICKETS_LINES";
/* <---- TICKETS ----> */

/* RELOCATES */
export const SET_RELOCATES = "SET_RELOCATES";
export const SET_RELOCATES_LINES = "SET_RELOCATES_LINES";
/* <---- RELOCATES ----> */

/* ORDERS */
export const SET_ORDERS = "SET_ORDERS";
/* <---- ORDERS ----> */

/* RETURNS */
export const SET_RETURNS = "SET_RETURNS";
export const SET_RETURNS_LINES = "SET_RETURNS_LINES";
export const SET_SHIPPING_AGENTS = "SET_SHIPPING_AGENTS";
export const SET_LOCATIONS = "SET_LOCATIONS";
export const SET_DELIVERYLINES = "SET_DELIVERYLINES";
/* <---- RETURNS ----> */

/* PRODUCTS */
export const SET_PRODUCTS = "SET_PRODUCTS";
/* <---- PRODUCTS ----> */
