import { handleActions, handleAction } from "redux-actions";
import {
  AUTH,
  SET_CURRENT_ROUTE,
  LOGOUT,
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_ALL_ROUTES,
  UPDATE_ROUTE,
  SET_SIDE_MENU_STATE,
  SET_SHIPMENTS,
  SET_RELOCATES,
  SET_RETURNS,
  SET_TICKETS,
  SET_SHIPMENTS_LINES,
  SET_RELOCATES_LINES,
  SET_RETURNS_LINES,
  SET_TICKETS_LINES,
  SET_SHIPPING_AGENTS,
  SET_LOCATIONS,
  SET_DELIVERYLINES,
  SET_ORDERS,
  SET_PRODUCTS,
} from "./../../constants";

/* BASIC */
export const authorization = handleActions(
  {
    [AUTH]: (state, action) => action.payload,
    [LOGOUT]: () => ({ auth: false }),
  },
  null
);

/* APP */
export const currentRoute = handleAction(
  SET_CURRENT_ROUTE,
  (state, action) => action.payload,
  {}
);

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({
      show: false,
      status: null,
      message: null,
    }),
  },
  { show: false, status: null, message: null }
);

export const routes = handleActions(
  {
    [SET_ALL_ROUTES]: (state, action) => action.payload,
    [UPDATE_ROUTE]: (state, action) => {
      const { keyapp } = action.payload;
      return { ...state, [keyapp]: action.payload };
    },
  },
  null
);

/* APP */
export const app = handleActions(
  {
    [SET_SIDE_MENU_STATE]: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
  },
  { isCollapsedSideMenu: true }
);

/* SHIPMENTS */
export const shipments = handleActions(
  {
    [SET_SHIPMENTS]: (state, action) => action.payload,
  },
  null
);

/* RELOCATES */
export const relocates = handleActions(
  {
    [SET_RELOCATES]: (state, action) => action.payload,
  },
  null
);

/* RETURNS */
export const returns = handleActions(
  {
    [SET_RETURNS]: (state, action) => action.payload,
  },
  null
);

/* TICKETS */
export const tickets = handleActions(
  {
    [SET_TICKETS]: (state, action) => action.payload,
  },
  null
);

/* ORDERS */
export const orders = handleActions(
  {
    [SET_ORDERS]: (state, action) => action.payload,
  },
  null
);

/* PRODUCTS */
export const products = handleActions(
  {
    [SET_PRODUCTS]: (state, action) => action.payload,
  },
  null
);

/* SHIPMENTSLINES */
export const shipmentsLines = handleActions(
  {
    [SET_SHIPMENTS_LINES]: (state, action) => action.payload,
  },
  null
);

/* RELOCATESLINES */
export const relocatesLines = handleActions(
  {
    [SET_RELOCATES_LINES]: (state, action) => action.payload,
  },
  null
);

/* RETURNSLINES */
export const returnsLines = handleActions(
  {
    [SET_RETURNS_LINES]: (state, action) => action.payload,
  },
  null
);

/* TICKETSLINES */
export const ticketsLines = handleActions(
  {
    [SET_TICKETS_LINES]: (state, action) => action.payload,
  },
  null
);

/* SET_SHIPPING_AGENTS */
export const shippingAgents = handleActions(
  {
    [SET_SHIPPING_AGENTS]: (state, action) => action.payload,
  },
  null
);

/* LOCATIONS */
export const locations = handleActions(
  {
    [SET_LOCATIONS]: (state, action) => action.payload,
  },
  null
);

/* DELIVERYLINES */
export const deliveryLines = handleActions(
  {
    [SET_DELIVERYLINES]: (state, action) => action.payload,
  },
  null
);
