import Axios from "axios";
import { useDispatch } from "react-redux";
import { SHOW_NOTIFICATION } from "../constants";

export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://almacen-api.app.elingenierojefe.es"
    : "http://localhost:3005";

export const axios = Axios.create({ baseURL: BASE_URL });

const ConnectionHandler = () => {
  const dispatch = useDispatch();

  const showNotification = (message = "HA OCURRIDO UN ERROR") => {
    dispatch({
      type: SHOW_NOTIFICATION,
      payload: { show: true, status: "error", message },
    });
  };

  axios.interceptors.request.use(
    async (request) => {
      return request;
    },
    (err) => {
      const {
        status = 0,
        data: { message = "" },
        config: { url = "" },
      } = err?.response || {};
      console.log("⛔️ REQUEST ERROR (Target)=> ", url);
      console.log("⛔️ REQUEST ERROR (Message)=> ", message);
      console.log("⛔️ REQUEST ERROR (Code) => ", status);
      console.log("➕➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➕");
      showNotification(`${message}`);
      // showNotification(`${message} => TARGET: ${url}`);
    }
  );

  axios.interceptors.response.use(
    (config) => {
      if (config.config.url === `/user/login`) {
        // setAccessToken(config.data.access_token);
      }
      return config;
    },
    (err) => {
      const {
        status = 0,
        data: { message = "" },
        config: { url = "" },
      } = err?.response || {};
      console.log("⛔️ RESPONSE ERROR (Target) => ", url);
      console.log("⛔️ RESPONSE ERROR (Message) => ", message);
      console.log("⛔️ RESPONSE ERROR (Code) => ", status);
      console.log("➕➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➕");
      switch (status) {
        default:
          break;
      }
      showNotification(`${message}`);
      return err?.response;
      // showNotification(`${message} => TARGET: ${url}`);
    }
  );

  return null;
};

export default ConnectionHandler;
