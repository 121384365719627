import { combineReducers } from "redux";
import {
  authorization,
  currentRoute,
  notifications,
  routes,
  app,
  shipments,
  relocates,
  tickets,
  returns,
  relocatesLines,
  returnsLines,
  shipmentsLines,
  ticketsLines,
  shippingAgents,
  locations,
  deliveryLines,
  orders,
  products,
} from "./Reducers";

export default combineReducers({
  app,
  authorization,
  currentRoute,
  notifications,
  routes,
  shipments,
  relocates,
  tickets,
  returns,
  relocatesLines,
  returnsLines,
  shipmentsLines,
  ticketsLines,
  shippingAgents,
  locations,
  deliveryLines,
  orders,
  products,
});
