import ReactDOM from "react-dom";
import App from "./App";
import history from "./history";
import { Provider } from "react-redux";
import { store } from "./store";
import { Router } from "react-router-dom";
import { ConfigProvider } from "antd";
import es_ES from "antd/es/locale/es_ES";
import MobileConsoleDebug from "./utils/MobileConsoleDebug";
import ConnectionHandler from "./connection/ConnectionHandler";
import NotificationsHandler from "./components/Notifications";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import "./styles/index.less";
import "./assets/fonts/index.less";

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<ConfigProvider locale={es_ES}>
					<App />
					<ConnectionHandler />
					<NotificationsHandler />
					<MobileConsoleDebug />
			</ConfigProvider>
		</Router>
	</Provider>,
	document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();