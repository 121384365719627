import { keyBy } from "lodash";
import { API } from "../constants/api";
import { axios } from "../connection/ConnectionHandler";
import { SET_ORDERS, SET_TICKETS, SET_TICKETS_LINES } from "../constants";
import { formatTickets, formatTicketsLines } from "./settingsActionsUtils";

export const fetchTickets = () => {
  return async (dispatch, getState) => {
    const response = await axios
      .get(API.ticket.fetch + getState().authorization.almacen)
      .then((response) => {
        const data = formatTickets(response.data);
        dispatch({
          type: SET_TICKETS,
          payload: keyBy(data, "value"),
        });
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const fetchOrders = () => {
  return async (dispatch, getState) => {
    const response = await axios
      .get(API.order.fetch + getState().authorization.almacen)
      .then((response) => {
        const data = formatTickets(response.data);
        dispatch({
          type: SET_ORDERS,
          payload: keyBy(data, "value"),
        });
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const fetchTicketsLines = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.ticket.lines)
      .then((response) => {
        const data = formatTicketsLines(response.data);
        dispatch({
          type: SET_TICKETS_LINES,
          payload: data,
        });
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const consultBlockTicket = (no) => {
  return async (dispatch) => {
    const response = await axios
      .get(API.ticket.isblock + no)
      .then((response) => {
        return response?.data;
      })
      .catch((err) => err);
    return response;
  };
};

export const blockTicket = (no) => {
  return async (dispatch, getState) => {
    const response = await axios
      .get(API.ticket.block + no + "/" + getState().authorization.user)
      .then((response) => {
        return response?.data;
      })
      .catch((err) => err);
    return response;
  };
};

export const createAsyncTicket = (no) => {
  return async (dispatch, getState) => {
    const response = await axios
      .get(API.ticket.create + no)
      .then((response) => {
        return response?.data;
      })
      .catch((err) => err);
    return response;
  };
};

export const processTicket = (data) => {
  return async (dispatch, getState) => {
    const response = await axios
      .post(API.ticket.process, data)
      .then((response) => {
        return response?.data;
      })
      .catch((err) => err);
    return response;
  };
};
