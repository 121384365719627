import { axios } from "../connection/ConnectionHandler";
import {
  APP_COOKIE_NAME,
  AUTH,
  PASSWORD_COOKIE_NAME,
  PASS_COOKIE_NAME,
  USER_COOKIE_NAME,
} from "../constants";
import { API } from "../constants/api";
import { deleteCookie, setCookie } from "../utils";
import { fetchRelocates, fetchRelocatesLines } from "./relocatesActions";
import {
  fetchDeliveryLines,
  fetchReturns,
  fetchReturnsLines,
  fetchShippingAgents,
} from "./returnsActions";
import { fetchShipments, fetchShipmentsLines } from "./shipmentActions";
import { fetchOrders, fetchTickets, fetchTicketsLines } from "./ticketActions";

const rememberLogin = (username, password, almacen, remember) => {
  if (remember) {
    setCookie(USER_COOKIE_NAME, username, 30);
    setCookie(PASSWORD_COOKIE_NAME, password, 30);
    setCookie(PASS_COOKIE_NAME, almacen, 30);
    setCookie(APP_COOKIE_NAME, APP_COOKIE_NAME, 30);
  } else {
    deleteCookie(USER_COOKIE_NAME, "");
    deleteCookie(PASS_COOKIE_NAME, "");
    deleteCookie(PASSWORD_COOKIE_NAME, "");
  }
};

/* INITAL DATA LOAD */
export const loadInitialData = () => {
  return async (dispatch) => {
    dispatch(fetchShipments());
    dispatch(fetchRelocates());
    dispatch(fetchReturns());
    dispatch(fetchTickets());

    dispatch(fetchShipmentsLines());
    dispatch(fetchRelocatesLines());
    dispatch(fetchReturnsLines());
    dispatch(fetchTicketsLines());

    dispatch(fetchShippingAgents());

    dispatch(fetchDeliveryLines());

    dispatch(fetchOrders());
  };
};

export const login = (username, password, almacen, remember) => {
  return async (dispatch) => {
    const response = await axios
      .post(API.auth.login, { username, password })
      .then((response) => {
        if (response?.status === 200) {
          rememberLogin(username, password, almacen, remember);
          dispatch({ type: AUTH, payload: { auth: true, username, password, almacen } });
        }
        return response;
      })
      .catch((err) => err);
    return response;
  };
};
