import { keyBy } from "lodash";
import { API } from "../constants/api";
import { axios } from "../connection/ConnectionHandler";
import { SET_SHIPMENTS, SET_SHIPMENTS_LINES } from "../constants";
import { formatShipments, formatShipmentsLines } from "./settingsActionsUtils";

export const fetchShipments = () => {
  return async (dispatch, getState) => {
    const response = await axios
      .get(API.shipment.fetch + getState().authorization.almacen)
      .then((response) => {
        const data = formatShipments(response.data);
        dispatch({
          type: SET_SHIPMENTS,
          payload: keyBy(data, "value"),
        });
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const fetchShipmentsLines = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.shipment.lines)
      .then((response) => {
        const data = formatShipmentsLines(response.data);
        dispatch({
          type: SET_SHIPMENTS_LINES,
          payload: data,
        });
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const consultBlockShipment = (no) => {
  return async (dispatch) => {
    const response = await axios
      .get(API.shipment.isblock + no)
      .then((response) => {
        return response?.data;
      })
      .catch((err) => err);
    return response;
  };
};

export const blockShipment = (no) => {
  return async (dispatch, getState) => {
    const response = await axios
      .get(API.shipment.block + no + "/" + getState().authorization.user)
      .then((response) => {
        return response?.data;
      })
      .catch((err) => err);
    return response;
  };
};

export const createAsyncShipment = (no) => {
  return async (dispatch, getState) => {
    const response = await axios
      .get(API.shipment.create + no)
      .then((response) => {
        return response?.data;
      })
      .catch((err) => err);
    return response;
  };
};

export const processShipment = (data) => {
  return async (dispatch, getState) => {
    const response = await axios
      .post(API.shipment.process, data)
      .then((response) => {
        return response?.data;
      })
      .catch((err) => err);
    return response;
  };
};

export const sendDelibery = (data) => {
  return async (dispatch, getState) => {
    const response = await axios
      .post(API.shipment.delibery, data)
      .then((response) => {
        return response?.data;
      })
      .catch((err) => err);
    return response;
  };
};
