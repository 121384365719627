export const ROLES = {
  sadmin: {
    label: "Sup. Admin.",
    role: "8fc02de6",
  },
  admin: {
    label: "Admin.",
    role: "a2470ee4",
  },
  user: {
    label: "Usuario",
    role: "97f567cf",
  },
};

export const ROLES_RAW = Object.values(ROLES).map((r) => r.role);

export const ROLE_OPTS = Object.values(ROLES).map((r) => ({
  label: r.label,
  value: r.role,
}));
