import { keyBy } from "lodash";
import { API } from "../constants/api";
import { axios } from "../connection/ConnectionHandler";
import { SET_RELOCATES, SET_RELOCATES_LINES } from "../constants";
import { formatRelocates, formatRelocatesLines } from "./settingsActionsUtils";

export const fetchRelocates = () => {
  return async (dispatch, getState) => {
    const response = await axios
      .get(API.relocate.fetch + getState().authorization.almacen)
      .then((response) => {
        const data = formatRelocates(response.data);
        dispatch({
          type: SET_RELOCATES,
          payload: keyBy(data, "value"),
        });
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const fetchRelocatesLines = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.relocate.lines)
      .then((response) => {
        const data = formatRelocatesLines(response.data);
        dispatch({
          type: SET_RELOCATES_LINES,
          payload: data,
        });
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const consultBlockRelocate = (no) => {
  return async (dispatch) => {
    const response = await axios
      .get(API.relocate.isblock + no)
      .then((response) => {
        return response?.data;
      })
      .catch((err) => err);
    return response;
  };
};

export const blockRelocate = (no) => {
  return async (dispatch, getState) => {
    const response = await axios
      .get(API.relocate.block + no + "/" + getState().authorization.user)
      .then((response) => {
        return response?.data;
      })
      .catch((err) => err);
    return response;
  };
};

export const changeUbication = (data) => {
  return async (dispatch, getState) => {
    const response = await axios
      .post(API.relocate.change, data)
      .then((response) => {
        return response;
      })
      .catch((err) => err);
    return response;
  };
};
