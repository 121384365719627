import { keyBy } from "lodash";
import { API } from "../constants/api";
import { axios } from "../connection/ConnectionHandler";
import {
  SET_DELIVERYLINES,
  SET_LOCATIONS,
  SET_RETURNS,
  SET_RETURNS_LINES,
  SET_SHIPPING_AGENTS,
} from "../constants";
import {
  formatDeliveryLines,
  formatLocations,
  formatReturns,
  formatReturnsLines,
  formatShippingAgents,
} from "./settingsActionsUtils";

export const fetchReturns = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.return.fetch)
      .then((response) => {
        const data = formatReturns(response.data);
        dispatch({
          type: SET_RETURNS,
          payload: keyBy(data, "value"),
        });
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const fetchDeliveryLines = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.return.deliveryLines)
      .then((response) => {
        const data = formatDeliveryLines(response.data);
        dispatch({
          type: SET_DELIVERYLINES,
          payload: data,
        });
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const fetchLocations = (client) => {
  return async (dispatch) => {
    const response = await axios
      .get(API.return.locations + client)
      .then((response) => {
        const data = formatLocations(response.data);
        dispatch({
          type: SET_LOCATIONS,
          payload: keyBy(data, "value"),
        });
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const fetchReturnsLines = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.return.lines)
      .then((response) => {
        const data = formatReturnsLines(response.data);
        dispatch({
          type: SET_RETURNS_LINES,
          payload: data,
        });
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const consultBlockReturn = (no) => {
  return async (dispatch) => {
    const response = await axios
      .get(API.return.isblock + no)
      .then((response) => {
        return response?.data;
      })
      .catch((err) => err);
    return response;
  };
};

export const blockReturn = (no) => {
  return async (dispatch, getState) => {
    const response = await axios
      .get(API.return.block + no + "/" + getState().authorization.user)
      .then((response) => {
        return response?.data;
      })
      .catch((err) => err);
    return response;
  };
};

export const fetchShippingAgents = (no) => {
  return async (dispatch, getState) => {
    const response = await axios
      .get(API.return.shippingAgents)
      .then((response) => {
        const data = formatShippingAgents(response.data);
        dispatch({
          type: SET_SHIPPING_AGENTS,
          payload: data,
        });
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const createReturn = (data) => {
  return async (dispatch, getState) => {
    const response = await axios
      .post(API.return.create, data)
      .then((response) => {
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const changeStateReturn = (data) => {
  return async (dispatch, getState) => {
    const response = await axios
      .post(API.return.state, data)
      .then((response) => {
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const setPicturesReturns = (data) => {
  return async (dispatch, getState) => {
    const response = await axios
      .post(API.return.pictures, data)
      .then((response) => {
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const deleteReturnLine = (data) => {
  return async (dispatch, getState) => {
    const response = await axios
      .post(API.return.lines, data)
      .then((response) => {
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const setPrintReturnLine = (data) => {
  return async (dispatch, getState) => {
    const response = await axios
      .post(API.return.print, data)
      .then((response) => {
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const setSendReturnLine = (data) => {
  return async (dispatch, getState) => {
    const response = await axios
      .post(API.return.send, data)
      .then((response) => {
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const setchangeLocation = (data) => {
  return async (dispatch, getState) => {
    const response = await axios
      .post(API.return.change, data)
      .then((response) => {
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const setSignReturn = (data) => {
  return async (dispatch, getState) => {
    const response = await axios
      .post(API.return.sign, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => err);
    return response;
  };
};

export const getPDFReturn = (no) => {
  return async (dispatch, getState) => {
    const response = await axios
      .get(API.return.pdf + no)
      .then((response) => {
        return response.data;
      })
      .catch((err) => err);
    return response;
  };
};
