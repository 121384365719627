import { isArray, isObject } from "lodash";

export const formatShipments = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: data.Descripcion,
      value: data.No,
      ...data,
    }));
  }
  if (isObject(data)) {
    return {
      label: data.Descripcion,
      value: data.No,
      ...data,
    };
  }
};

export const formatShipmentsLines = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: data.Descripcion,
      value: data.No,
      ...data,
    }));
  }
  if (isObject(data)) {
    return {
      label: data.Descripcion,
      value: data.No,
      ...data,
    };
  }
};

export const formatRelocates = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: data.Descripcion,
      value: data.No,
      ...data,
    }));
  }
  if (isObject(data)) {
    return {
      label: data.Descripcion,
      value: data.No,
      ...data,
    };
  }
};

export const formatRelocatesLines = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: data.Descripcion,
      value: data.No,
      ...data,
    }));
  }
  if (isObject(data)) {
    return {
      label: data.Descripcion,
      value: data.No,
      ...data,
    };
  }
};

export const formatTickets = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: data.Descripcion,
      value: data.No,
      ...data,
    }));
  }
  if (isObject(data)) {
    return {
      label: data.Descripcion,
      value: data.No,
      ...data,
    };
  }
};

export const formatTicketsLines = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: data.Descripcion,
      value: data.No,
      ...data,
    }));
  }
  if (isObject(data)) {
    return {
      label: data.Descripcion,
      value: data.No,
      ...data,
    };
  }
};

export const formatReturns = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: data.Descripcion,
      value: data.No,
      ...data,
    }));
  }
  if (isObject(data)) {
    return {
      label: data.Descripcion,
      value: data.No,
      ...data,
    };
  }
};

export const formatDeliveryLines = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: data.Descripcion,
      value: data.No,
      ...data,
    }));
  }
  if (isObject(data)) {
    return {
      label: data.Descripcion,
      value: data.No,
      ...data,
    };
  }
};

export const formatReturnsLines = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: data.Descripcion,
      value: data.No,
      ...data,
    }));
  }
  if (isObject(data)) {
    return {
      label: data.Descripcion,
      value: data.No,
      ...data,
    };
  }
};

export const formatShippingAgents = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: data.Name,
      value: data.Code,
      ...data,
    }));
  }
  if (isObject(data)) {
    return {
      label: data.Name,
      value: data.Code,
      ...data,
    };
  }
};

export const formatLocations = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: `${data.Code} - ${data.Name} - ${data.Address}`,
      value: data.Code,
      ...data,
    }));
  }
  if (isObject(data)) {
    return {
      label: `${data.Code} - ${data.Name} - ${data.Address}`,
      value: data.Code,
      ...data,
    };
  }
};
