// https://testing-core.deploy.kuarasoftware.es/

export const API = {
  auth: {
    login: `/login`,
  },
  order: {
    fetch: `/orders/`,
  },
  products: {
    fetch: `/products/`,
    reclassify: `/products/reclassify`,
    stock: `/products/stock`,
  },
  shipment: {
    fetch: `/shipments/`,
    lines: `/shipmentsLines`,
    block: `/shipments/block/`,
    unblock: `/shipments/unblock/`,
    isblock: `/shipments/isblock/`,
    create: `/shipments/create/`,
    process: `/shipments/process`,
    delibery: `/shipments/delibery`,
  },
  ticket: {
    fetch: `/tickets/`,
    lines: `/ticketsLines`,
    block: `/tickets/block/`,
    unblock: `/tickets/unblock/`,
    isblock: `/tickets/isblock/`,
    create: `/tickets/create/`,
    process: `/tickets/process`,
  },
  relocate: {
    fetch: `/relocates/`,
    lines: `/relocatesLines`,
    block: `/relocates/block/`,
    unblock: `/relocates/unblock/`,
    isblock: `/relocates/isblock/`,
    change: `/relocates`,
  },
  return: {
    fetch: `/returns`,
    create: `/returns`,
    state: `/returns/state`,
    lines: `/returnsLines`,
    block: `/return/block/`,
    unblock: `/return/unblock/`,
    isblock: `/return/isblock/`,
    shippingAgents: `/returns/ShippingAgents`,
    pictures: `/returns/pictures`,
    print: `/returns/print`,
    send: `/returns/send`,
    locations: `/locations/`,
    change: `/returns/location`,
    deliveryLines: `/deliveryLines`,
    pdf: `/return/pdf/`,
    sign: `/return/sign/`,
  },
};
