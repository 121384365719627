import { createSelector } from "reselect";
import moment from "moment";
import "moment/locale/es";
import { ROLES_RAW } from "../../constants/permissions";
moment().locale("es");

/* APP */
export const isUserAuthorized = createSelector(
  (state) => state.authorization,
  (authorization) => authorization
);
export const getCurrentRoute = createSelector(
  (state) => state.currentRoute,
  (currentRoute) => currentRoute
);
export const getCurrentRouteTitle = createSelector(
  getCurrentRoute,
  (route) => route.title
);

export const getUserProfile = createSelector(
  (state) => state.profile,
  (profile) => profile
);

export const getCurrentUserRole = createSelector(getUserProfile, (profile) =>
  ROLES_RAW.includes(profile?.role) ? profile?.role : null
);

/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);

/* ROUTES */
export const getRoutes = createSelector(
  (state) => state.routes,
  (routes) => routes
);
export const getRoutesObject = createSelector(
  (state) => state.routes,
  (routes) => routes
);

/* SHIPMENT */
export const getShipments = createSelector(
  (state) => state.shipments,
  (shipments) => shipments
);

/* RELOCATES */
export const getRelocates = createSelector(
  (state) => state.relocates,
  (relocates) => relocates
);

/* TICKETS */
export const getTickets = createSelector(
  (state) => state.tickets,
  (tickets) => tickets
);

/* RETURNS */
export const getReturns = createSelector(
  (state) => state.returns,
  (returns) => returns
);

/* SHIPMENTLINES */
export const getShipmentsLines = createSelector(
  (state) => state.shipmentsLines,
  (shipmentsLines) => shipmentsLines
);

/* RELOCATESLINES */
export const getRelocatesLines = createSelector(
  (state) => state.relocatesLines,
  (relocatesLines) => relocatesLines
);

/* TICKETSLINES */
export const getTicketsLines = createSelector(
  (state) => state.ticketsLines,
  (ticketsLines) => ticketsLines
);

/* RETURNSLINES */
export const getReturnsLines = createSelector(
  (state) => state.returnsLines,
  (returnsLines) => returnsLines
);

/* SHIPPINGAGENTS */
export const getShippingAgents = createSelector(
  (state) => state.shippingAgents,
  (shippingAgents) => shippingAgents
);

/* LOCATIONS */
export const getLocations = createSelector(
  (state) => state.locations,
  (locations) => locations
);

/* DELIVERYLINES */
export const getDeliveryLines = createSelector(
  (state) => state.deliveryLines,
  (deliveryLines) => deliveryLines
);

/* ORDERS */
export const getOrders = createSelector(
  (state) => state.orders,
  (orders) => orders
);

/* PRODUCTS */
export const getProducts = createSelector(
  (state) => state.products,
  (products) => products
);
